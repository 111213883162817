import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path:'/fb/pelletheizung',
    name:'pelletheizung',
    component:() => import('../views/fb/FotobegehungView.vue')
  },
  {
    path:'/fb/photovoltaik',
    name:'photovoltaik',
    component:() => import('../views/fb/FotobegehungViewPV.vue')
  },
  {
    path:'/fb/abnahme',
    name:'abnahme',
    component:() => import('../views/fb/AbnahmeView.vue')
  },
  {
    path:'/fb',
    name:'fb',
    component:() => import('../views/FotobegehungMenuView.vue')
  },
  {
    path:'/documentation',
    name:'documentation',
    component:() => import('../views/DokumentationView.vue')
  },
  {
    path:'/kurs',
    name:'kurs',
    component:() => import('../views/KursView.vue')
  },
  {
    path:'/chat',
    name:'chat',
    component:() => import('../views/ChatView.vue')
  },
  {
    path:'/video',
    name:'video',
    component:() => import('../views/VideoView.vue')
  },
  {
    path:'/login',
    name:'login',
    component:() => import('../views/LoginView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyView.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ImpressumView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // ...
  //clear intervall
  clearInterval(window.interval)
  // explicitly return false to cancel the navigation
  const userIsLoggedIn = localStorage.getItem('logged_in');
  const token = localStorage.getItem('login_token');
  console.log(to.name)
  if (userIsLoggedIn !== 1 && token == null && to.name !== "login" && to.name !== "photovoltaik" && to.name !== "pelletheizung" && to.name !== "privacy" && to.name !== "HomeView" && to.name !=="kurs" && to.name !== "impressum" && to.name !== "documentation" && to.name !== 'fb' && to.name !=="abnahme") {
    if(to.name == "chat" && document.location.search.indexOf("username") !== - 1 && document.location.search.indexOf("plz") !== - 1){
      const url = document.location.href;
      const searchParams = new URLSearchParams(new URL(url).search);
      var user = {}
      user['email'] = searchParams.get('useremail')
      user['name'] = searchParams.get('username')
      user['zip'] = searchParams.get('plz')
      localStorage.setItem('user', JSON.stringify(user))
      next()
    }else if(to.name == "chat" && localStorage.getItem("user") !== null){
      next()
    }else{
      next({ name: 'login' })
    }

  } else {
    next()
  }
})

export default router
