<template>
  <v-app>
  <v-navigation-drawer
     v-model="drawer"
     app
   >
         <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
            </v-list-item-title>
            <v-list-item-subtitle>
                <v-spacer></v-spacer>

                <v-img
                  max-height="110"
                  max-width="180"
                  src="../src/assets/solarvent.png"
                ></v-img>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list
          dense
          nav
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
          >
          <router-link style="display:flex" tag="div" class="col" class-active="active" :to="item.url" exact>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </router-link>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="item in items2"
            :key="item.title"
            link
          >
          <router-link style="display:flex" tag="div" class="col" class-active="active" :to="item.url" exact>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </router-link>
          </v-list-item>
        </v-list>
   </v-navigation-drawer>

   <v-app-bar app>
     <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

     <v-toolbar-title>
     <div style="color:red;" v-if="$admin.value">ADMIN ANSICHT</div>
     <v-spacer></v-spacer>
     </v-toolbar-title>
       <v-img
        style="position: absolute;right: 35px;"
         v-if="!drawer"
         max-height="110"
         max-width="180"
         src="../src/assets/solarvent.png"
       ></v-img>
       <v-icon
          @click="logMeOut()"
          v-if="$logout.value"
          class="logout"
          medium
       >
         mdi-logout
       </v-icon>
     </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
      <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
        >
          {{ SnackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="green"
              text
              v-bind="attrs"
              @click="pushNotification_activate()"
            >
              Aktivieren
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
        v-model="snackbarLogout"
        :timeout="timeoutLogout"
        >
        {{ textLogout }}
        </v-snackbar>
  </v-app>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
export default {
  name: 'App',

  data: () => ({
    drawer: null,
    snackbar:false,
    SnackbarText:"Benachrichtigungen aktivieren?",
    notification:true,
    notificationMessage:"",
    error:false,
    errorMessage:"",
    firebaseApp:'',
    messaging:'',
    snackbarLogout: false,
    textLogout: "Abmeldung erfolgreich",
    timeoutLogout: 2000,
    timeout: -1,
    items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard', url: '/' },
          { title: 'Fotobegehung', icon: 'mdi-image-multiple', url: '/fb' },
          { title: 'Selbstbaukurs', icon: 'mdi-school-outline', url:'/kurs' },
          { title: 'Chat', icon: 'mdi-wechat', url:'/chat' },
          { title: 'Video', icon: 'mdi-video', url:'/video' },
          //{ title: 'doc', icon: 'mdi-file-document', url:'/documentation' },
        ],
    items2:[
          { title: 'Datenschutz', icon: 'mdi-database-search', url:'/privacy' },
          { title: 'Impressum', icon: 'mdi-database-search', url:'/impressum' }
    ],
    firebaseConfig:{
            apiKey: "AIzaSyC42KLUQzZIa72vTvynRs_DSGGLb59nDug",
            authDomain: "pushnotification-8dfbd.firebaseapp.com",
            projectId: "pushnotification-8dfbd",
            storageBucket: "pushnotification-8dfbd.appspot.com",
            messagingSenderId: "541215419219",
            appId: "1:541215419219:web:3d4773409dc5f4d7344fa6",
            measurementId: "G-0MDDMGR4LB"
    },
  }),
  watch: {
  '$logout'(newValue) {
      console.log(newValue)

    }
  },
  mounted(){
      var _this = this;
      console.log(document.cookie.indexOf("pushNotification"),_this.$logout.value )
      if(document.cookie.indexOf("pushNotification") == -1 && _this.$logout.value == false){
            this.snackbar = true;

      }



            _this.firebaseApp = initializeApp(_this.firebaseConfig);
            _this.messaging = getMessaging(_this.firebaseApp);
            onMessage(_this.messaging, (payload) => {
              console.log('Message received. ', payload);

              // ...
            });
      if(localStorage.getItem("login_token") !== null && localStorage.getItem("logged_in") !== null && localStorage.getItem("user") !== null){
        _this.$logout.value = true;
      }

  },
  methods:{
    logMeOut(){
        localStorage.removeItem("login_token")
        localStorage.removeItem("logged_in")
        localStorage.removeItem("user")
        this.$logout.value = false;
        this.$router.go(0);
        this.snackbarLogout = true;
    },
    pushNotification_activate(){
        var _this = this;
        _this.snackbar = false;

        getToken(_this.messaging, { vapidKey: 'BBHnKcptmY-pRjhukRhWsepvEFIN39xdk8Ws7bPEGf29RYKELwG--rRW8i62RLRFss4LfcL2mkSjbhijHeUjvPE' }).then((currentToken) => {
          if (currentToken) {
             console.log(currentToken)
             if(localStorage.getItem("user") !== null){
                if(typeof JSON.parse(localStorage.getItem("user")).email !== 'undefined'){
                    var email = JSON.parse(localStorage.getItem("user")).email
                }else{
                    email = JSON.parse(localStorage.getItem("user")).EmailOrName
                }
                 _this.axios.post('https://fotobegehung.de/api/setPushToken', {
                      email: email,
                      token: currentToken
                    })
                    .then(function (response) {
                      if(response.status == 200){
                          console.log(response)
                          var CookieDate = new Date;
                           CookieDate.setFullYear(CookieDate.getFullYear() +10);
                           document.cookie = 'pushNotification=1; expires=' + CookieDate.toUTCString() + ';';

                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
             }
            // Send the token to your server and update the UI if necessary
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });

    },
  }
};
</script>
<style>
.logout{
  position: absolute!important;
  right: 10px!important;
}
</style>
