<template>
       <div>
       <v-alert
          outlined
          type="info"
          prominent
          border="left"
          color="black"
          >
          Bitte wähle eine Kategorie aus.
          </v-alert>
          <v-item-group mandatory>
             <v-container>
               <v-row class="center">
                 <div
                  style="max-width:290!important;margin-right:5px;margin-bottom:10px;"
                 >
                 <router-link style="cursor:pointer;" tag="div" S to="/fb" exact>
                   <v-item>
                     <v-card
                       class="d-flex align-center"
                       dark
                       color="rgb(255 205 15)"
                       height="200"
                       width="270"
                     >
                       <v-scroll-y-transition>
                         <div
                           class="text-h2 flex-grow-1 text-center"
                           style="font-size:40px!important;display:flex;flex-direction:column;"
                         >
                         <v-icon
                             large
                             color="white"
                           >
                             mdi-image-multiple
                           </v-icon>
                           Fotobegehung
                         </div>
                       </v-scroll-y-transition>
                     </v-card>
                   </v-item>
                  </router-link>
                 </div>
                 <div
                 style="max-width:290!important;margin-right:5px;margin-bottom:10px;"
                 >
                 <router-link style="cursor:pointer;" tag="div" S to="/kurs" exact>
                   <v-item>
                     <v-card
                       class="d-flex align-center"
                       dark
                       color="rgb(255 205 15)"
                       height="200"
                       width="270"
                     >
                       <v-scroll-y-transition>
                         <div
                           class="text-h2 flex-grow-1 text-center"
                           style="font-size:40px!important;display:flex;flex-direction:column;"
                         >
                         <v-icon
                             large
                             color="white"
                           >
                             mdi-school-outline
                           </v-icon>
                           Selbstbaukurs
                         </div>
                       </v-scroll-y-transition>
                     </v-card>
                   </v-item>
                  </router-link>
                 </div>

                 <div
                  style="max-width:290!important;margin-right:5px;margin-bottom:10px;"
                 >
                 <router-link style="cursor:pointer;" tag="div" S to="/chat" exact>
                   <v-item>
                     <v-card
                       class="d-flex align-center"
                       dark
                       color="rgb(255 205 15)"
                       height="200"
                       width="270"
                     >
                       <v-scroll-y-transition>
                         <div
                           class="text-h2 flex-grow-1 text-center"
                           style="font-size:40px!important;display:flex;flex-direction:column;"
                         >
                         <v-icon
                             large
                             color="white"
                           >
                             mdi-chat-outline
                           </v-icon>
                           Chat
                         </div>

                       </v-scroll-y-transition>
                     </v-card>
                   </v-item>
                  </router-link>
                 </div>

                 <div
                  style="max-width:290!important;margin-right:5px;margin-bottom:10px;"
                 >
                 <router-link style="cursor:pointer;" tag="div" S to="/video" exact>
                   <v-item>
                     <v-card
                       class="d-flex align-center"
                       dark
                       color="rgb(255 205 15)"
                       height="200"
                       width="270"
                     >
                       <v-scroll-y-transition>
                         <div
                           class="text-h2 flex-grow-1 text-center"
                           style="font-size:40px!important;display:flex;flex-direction:column;"
                         >
                         <v-icon
                             large
                             color="white"
                           >
                             mdi-video
                           </v-icon>
                           Video
                         </div>

                       </v-scroll-y-transition>
                     </v-card>
                   </v-item>
                  </router-link>
                 </div>

                 <div
                  v-if="login == false"
                  style="max-width:290!important;margin-right:5px;margin-bottom:10px;"
                 >
                 <router-link style="cursor:pointer;" tag="div" S to="/login" exact>
                   <v-item>
                     <v-card
                       class="d-flex align-center"
                       dark
                       color="rgb(255 205 15)"
                       height="200"
                       width="270"
                     >
                       <v-scroll-y-transition>
                         <div
                           class="text-h2 flex-grow-1 text-center"
                           style="font-size:40px!important;display:flex;flex-direction:column;"
                         >
                         <v-icon
                             large
                             color="white"
                           >
                             mdi-login
                           </v-icon>
                           Login
                         </div>

                       </v-scroll-y-transition>
                     </v-card>
                   </v-item>
                  </router-link>
                 </div>

               </v-row>
             </v-container>
           </v-item-group>
       </div>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
        login:false
    }),
    mounted(){
        if(localStorage.getItem("logged_in") !== null){
          this.login = true
        }
    },
  }
</script>
<style>
@media only screen and (max-width: 600px)  {
  .center{
    justify-content:center
  }
}
</style>
