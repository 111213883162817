import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueOffline from 'vue-offline'

//import VuePeerJS from 'vue-peerjs';
//import Peer from 'peerjs';
import VueTour from 'vue-tour'

import './registerServiceWorker'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)
Vue.use(VueAxios, axios)
Vue.use(VueOffline)
//Vue.use(VuePeerJS, new Peer({}));
Vue.prototype.$logout = {value: false};
Vue.prototype.$admin = {value: false};
Vue.observable({ $logout: {} })
Vue.config.productionTip = true



new Vue({
  router,
  vuetify,

  render: h => h(App)
}).$mount('#app')
